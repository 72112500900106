/**
 * @generated SignedSource<<c7b7e82128b15f421cf3a5cdd48f1f17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Ordering = "ASC" | "DESC" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitDFilter = {
  AND?: UnitDFilter | null;
  NOT?: UnitDFilter | null;
  OR?: UnitDFilter | null;
  courseId_In?: ReadonlyArray<string> | null;
  courseTitle_Contains?: string | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: SchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitDOrder = {
  description?: Ordering | null;
  id?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type UnitDSelectField_unitDsQuery$variables = {
  filters?: UnitDFilter | null;
  order?: UnitDOrder | null;
};
export type UnitDSelectField_unitDsQuery$data = {
  readonly unitDs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly id: string;
      };
    }>;
  };
};
export type UnitDSelectField_unitDsQuery = {
  response: UnitDSelectField_unitDsQuery$data;
  variables: UnitDSelectField_unitDsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "UnitDConnection",
    "kind": "LinkedField",
    "name": "unitDs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnitDEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitD",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitDSelectField_unitDsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitDSelectField_unitDsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4c26426e6b21bd70662347e3b7ef8eb4",
    "id": null,
    "metadata": {},
    "name": "UnitDSelectField_unitDsQuery",
    "operationKind": "query",
    "text": "query UnitDSelectField_unitDsQuery(\n  $filters: UnitDFilter\n  $order: UnitDOrder\n) {\n  unitDs(filters: $filters, order: $order) {\n    edges {\n      node {\n        id\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3581b00aeeb6645a46281b89a765d35f";

export default node;
