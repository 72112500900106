/**
 * @generated SignedSource<<5f0833898772b105674bd9b85e134f05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitDFilter = {
  AND?: UnitDFilter | null;
  NOT?: UnitDFilter | null;
  OR?: UnitDFilter | null;
  courseId_In?: ReadonlyArray<string> | null;
  courseTitle_Contains?: string | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: SchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitDOrder = {
  description?: Ordering | null;
  id?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type TaskSecondLabelingDialog_unitDsQuery$variables = {
  after?: string | null;
  filters?: UnitDFilter | null;
  first?: number | null;
  order?: UnitDOrder | null;
};
export type TaskSecondLabelingDialog_unitDsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UnitDPaginator_query">;
};
export type TaskSecondLabelingDialog_unitDsQuery = {
  response: TaskSecondLabelingDialog_unitDsQuery$data;
  variables: TaskSecondLabelingDialog_unitDsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskSecondLabelingDialog_unitDsQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "UnitDPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskSecondLabelingDialog_unitDsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnitDConnection",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitDEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitATitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitBTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitCTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "UnitDPaginator_query_unitDs",
        "kind": "LinkedHandle",
        "name": "unitDs"
      }
    ]
  },
  "params": {
    "cacheID": "49c42ff0dd0e4719fc7c80bbbf228297",
    "id": null,
    "metadata": {},
    "name": "TaskSecondLabelingDialog_unitDsQuery",
    "operationKind": "query",
    "text": "query TaskSecondLabelingDialog_unitDsQuery(\n  $filters: UnitDFilter\n  $order: UnitDOrder\n  $first: Int\n  $after: String\n) {\n  ...UnitDPaginator_query_2rfEMg\n}\n\nfragment UnitDItem_unitD on UnitD {\n  id\n  unitATitle\n  unitBTitle\n  unitCTitle\n  description\n}\n\nfragment UnitDPaginator_query_2rfEMg on Query {\n  unitDs(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        description\n        ...UnitDItem_unitD\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4eacf02b49d6f246fbbeddaeadb72fa7";

export default node;
